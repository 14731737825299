body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.PreviewContainer {
  overflow: hidden;
  margin: 20px 0;
}
.PreviewContainer > img {
  height: max-content;
  object-fit: contain;
  position: relative;
  max-width: 700px;
  margin: 0 auto;
  display: block;
}
.PreviewContainer .react-pdf__Document {
  max-width: fit-content;
  margin: 0 auto;
}
.PreviewContainer .paging > p {
  margin: 0.5rem 0;
  display: inline-block;
}
.PreviewContainer .paging > .buttons {
  float: right;
  margin: 0.5rem 0;
}
.PreviewContainer .paging > .buttons > button:first-child {
  margin-right: 0.5rem;
}
.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}

.pcenter {
  margin: 0 auto;
  text-align: center;
}

.carousel-slider .control-dots {
  display: none;
}
