.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logoContainer {
  position: relative;
  width: 7rem;
  height: 7rem;
}

.logo {
  width: 6rem;
  height: 6rem;
}

.absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.ring {
  display: inline-block;
  position: relative;
  width: 7rem;
  height: 7rem;
}
.ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 7rem;
  height: 7rem;
  border: 4px solid #2e5bff;
  border-radius: 50%;
  animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2e5bff transparent transparent transparent;
}

.ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pleaseWait {
  margin-top: 2rem;
}
